import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useCollectionOnce } from "react-firebase-hooks/firestore";
import {
  collection,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { db, fetchDocDictionary } from "../../services/firebase";
import TransactionDataService from "../../services/transactions.service";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import BackspaceTwoToneIcon from "@mui/icons-material/BackspaceTwoTone";
import RefreshIcon from "@mui/icons-material/Refresh";
import Typography from "@mui/material/Typography";

import { Formik, Form, Field } from "formik";
import { CustomizedSelect } from "../../common/select";

import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";


import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";

import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import moment from "moment";

export const ReportPurchaseListing = () => {
  const [dateBegin, setDateBegin] = useState(dayjs());
  const [dateEnd, setDateEnd] = useState(dayjs());
  const formikRef = useRef();
  const navigate = useNavigate();

  const [tableLoading, setTableLoading] = useState(false);
  const [products, setProducts] = useState();
  const [purchases, setPurchases] = useState([]);
  const [sales, setSales] = useState([]);
  const [transfers, setTransfers] = useState([]);

  const [valueTab, setValueTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const [locations, locationsLoading, locationsError] = useCollectionOnce(
    query(
      collection(db, "locations-p"),
      where("active", "==", true),
      orderBy("name")
    )
  );

  const [users, usersLoading, usersError] = useCollectionOnce(
    query(
      collection(db, "users"),
      orderBy("name")
    )
  );

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("dateBegin", dateBegin);
    }
  }, [dateBegin]);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("dateEnd", dateEnd);
    }
  }, [dateEnd]);

  const purchaseColumns = [
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      headerAlign: "left",
      editable: false,
    },
    {
      field: "reportingDate",
      headerName: "Date",
      flex: 1,
      type: "dateTime",
      valueGetter: ({ value }) => value && value.toDate(),
      renderCell: (params) =>
        moment(params.row.reportingDate.toDate()).format("M/D/YY"),
    },
    {
      field: "tankNumber",
      headerName: "Tank Number",
      flex: 0.5,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    { field: "productName", headerName: "Product", flex: 1, editable: false },
    { field: "manifest", headerName: "Manifest", flex: 1, editable: false },
    {
      field: "gallons",
      headerName: "Gallons",
      flex: 1,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    { field: "userName", headerName: "User", flex: 1, editable: false },
    {
      field: "createdDate",
      headerName: "Created",
      flex: 1,
      type: "dateTime",
      editable: false,
      valueGetter: ({ value }) => value && value.toDate(),
      renderCell: (params) =>
        moment(params.row.reportingDate.toDate()).format("M/D/YY"),
    },
  ];

  const saleColumns = [
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      headerAlign: "left",
      editable: false,
    },
    {
      field: "reportingDate",
      headerName: "Date",
      flex: 1,
      type: "dateTime",
      valueGetter: ({ value }) => value && value.toDate(),
      renderCell: (params) =>
        moment(params.row.reportingDate.toDate()).format("M/D/YY"),
    },
    { field: "productName", headerName: "Product", flex: 1, editable: false },
    {
      field: "gallons",
      headerName: "Gallons",
      flex: 1,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    { field: "userName", headerName: "User", flex: 1, editable: false },
    {
      field: "createdDate",
      headerName: "Created",
      flex: 1,
      type: "dateTime",
      editable: false,
      valueGetter: ({ value }) => value && value.toDate(),
      renderCell: (params) =>
        moment(params.row.reportingDate.toDate()).format("M/D/YY"),
    },
  ];

  const transferColumns = [
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      headerAlign: "left",
      editable: false,
    },
    {
      field: "reportingDate",
      headerName: "Date",
      flex: 1,
      type: "dateTime",
      valueGetter: ({ value }) => value && value.toDate(),
      renderCell: (params) =>
        moment(params.row.reportingDate.toDate()).format("M/D/YY"),
    },
    { field: "transfer", headerName: "Transfer", flex: 1, editable: false },
    { field: "productName", headerName: "Product", flex: 1, editable: false },
    {
      field: "gallons",
      headerName: "Gallons",
      flex: 1,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    { field: "userName", headerName: "User", flex: 1, editable: false },
    {
      field: "createdDate",
      headerName: "Created",
      flex: 1,
      type: "dateTime",
      editable: false,
      valueGetter: ({ value }) => value && value.toDate(),
      renderCell: (params) =>
        moment(params.row.reportingDate.toDate()).format("M/D/YY"),
    },
  ];

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      spacing={1}
    >
      <Box display="flex" justifyContent="space-between" p={2}>
        <Typography variant="h5">Transaction Listing</Typography>

        <Box display="flex">
          <Button
            variant="text"
            onClick={() => navigate('/survey')}
            startIcon={<BackspaceTwoToneIcon />}
          >
            Back
          </Button>
        </Box>
      </Box>
      <Formik
        innerRef={formikRef}
        initialValues={{ locationId: "", dateBegin: dayjs(), dateEnd: dayjs() }}
        onSubmit={async (values, helpers) => {
          console.log("sumbitted", values);

          setTableLoading(true);

          let productsDictionary = products;

          if (!productsDictionary) {
            const dict = await fetchDocDictionary(
              query(collection(db, "products-p"))
            );
            setProducts(dict);
            productsDictionary = dict;
          }

          if (values.locationId) {
            const response = await TransactionDataService.getForLocation(
              values.locationId,
              dateBegin.startOf("day").toDate(),
              dateEnd.startOf("day").toDate()
            );

            const p = [];
            const s = [];
            const tx = [];

            response.data.forEach((t) => {
              const location = locations.docs.find((l) => l.id === t.locationId);
              const user = users.docs.find((u) => u.id === t.lastUpdatedBy);
  
              switch (t.type) {
                case "purchase":
                  p.push({
                    id: t.id,
                    location: location?.data()?.name,
                    tankNumber: t.tankNumber,
                    productName: productsDictionary[t.productId]?.name,
                    productMacro: productsDictionary[t.productId]?.macro,
                    manifest: t.manifest,
                    gallons: t.gallons,
                    reportingDate: dayjs(t.reportingDate),
                    userName: user?.data()?.email,
                    createdDate: dayjs(t.createdAt),
                  });
                  break;
                case "sale":
                  s.push({
                    id: t.id,
                    location: location?.data()?.name,
                    productName: productsDictionary[t.productId]?.name,
                    productMacro: productsDictionary[t.productId]?.macro,
                    gallons: t.gallons,
                    reportingDate: dayjs(t.reportingDate),
                    userName: user?.data()?.email,
                    createdDate: dayjs(t.createdAt),
                  });
                  break;
                case "in":
                  tx.push({
                    id: t.id,
                    location: location?.data()?.name,
                    transfer: 'In',
                    productName: productsDictionary[t.productId]?.name,
                    productMacro: productsDictionary[t.productId]?.macro,
                    gallons: t.gallons,
                    reportingDate: dayjs(t.reportingDate),
                    userName: user?.data()?.email,
                    createdDate: dayjs(t.createdAt),
                  });
                  break;
                case "out":
                  tx.push({
                    id: t.id,
                    location: location?.data()?.name,
                    transfer: 'Out',
                    productName: productsDictionary[t.productId]?.name,
                    productMacro: productsDictionary[t.productId]?.macro,
                    gallons: t.gallons,
                    reportingDate: dayjs(t.reportingDate),
                    userName: user?.data()?.email,
                    createdDate: dayjs(t.createdAt),
                  });
                  break;
                default:
                  break;
              }
            });

            //console.log("p", p);
            setPurchases(
              p.sort((t1, t2) =>
                t1.tankNumber > t2.tankNumber
                  ? 1
                  : t1.tankNumber < t2.tankNumber
                  ? -1
                  : t1.reportingDate > t2.reportingDate
                  ? 1
                  : t1.reportingDate < t2.reportingDate
                  ? -1
                  : 0
              )
            );
            setSales(
              s.sort((t1, t2) =>
                t1.reportingDate > t2.reportingDate
                  ? 1
                  : t1.reportingDate < t2.reportingDate
                  ? -1
                  : t1.productName > t2.productName
                  ? 1
                  : t1.productName < t2.productName
                  ? -1
                  : 0
              )
            );
            setTransfers(
              tx.sort((t1, t2) =>
                t1.reportingDate > t2.reportingDate
                  ? 1
                  : t1.reportingDate < t2.reportingDate
                  ? -1
                  : t1.transfer > t2.transfer
                  ? 1
                  : t1.transfer < t2.transfer
                  ? -1
                  : t1.productName > t2.productName
                  ? 1
                  : t1.productName < t2.productName
                  ? -1
                  : 0
              )
            );
          } else {
            //console.log("No location");
            setPurchases([]);
            setSales([]);
            setTransfers([]);
          }

          //const done = await new Promise((res) => setTimeout(res, 2500));
          setTableLoading(false);
        }}
      >
        {({ values, errors, isSubmitting }) => (
          <Form>
            {locationsError && (
              <Grid item xs={12}>
                <strong>Error: {JSON.stringify(locationsError)}</strong>
              </Grid>
            )}
            {locationsLoading && (
              <Grid item xs={12}>
                <Skeleton />
              </Grid>
            )}
            {locations && (
              <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                  <Field
                    fullWidth
                    component={CustomizedSelect}
                    label="Select Location"
                    name={`locationId`}
                  >
                    <MenuItem value="" name="Location Not Selected" selected>
                      Location Not Selected
                    </MenuItem>
                    {locations.docs.map((doc) => (
                      <MenuItem
                        key={doc.id}
                        value={doc.id}
                        name={doc.data().name}
                      >
                        {doc.data().name}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>

                <Grid item xs={6} md={3}>
                  <DatePicker
                    fullWidth
                    name="dateBegin"
                    label="Begin"
                    value={dateBegin}
                    onChange={(newValue) => setDateBegin(newValue)}
                  ></DatePicker>
                </Grid>
                <Grid item xs={6} md={3}>
                  <DatePicker
                    fullWidth
                    name="dateEnd"
                    label="End"
                    value={dateEnd}
                    onChange={(newValue) => setDateEnd(newValue)}
                  ></DatePicker>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                    startIcon={
                      isSubmitting ? (
                        <CircularProgress size="0.9rem" />
                      ) : (
                        <RefreshIcon />
                      )
                    }
                  >
                    {isSubmitting ? "Refreshing" : "Refresh"}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Form>
        )}
      </Formik>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={valueTab}
            onChange={handleChangeTab}
            aria-label="tabs"
            centered
            variant="fullWidth"
          >
            <Tab label="Sales" {...a11yProps(0)} />
            <Tab label="Transfers" {...a11yProps(1)} />
            <Tab label="Purchases" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={valueTab} index={0}> {sales && (
           <DataGrid
             loading={tableLoading}
             autoHeight
             density="compact"
             slots={{ toolbar: GridToolbar }}
             columns={saleColumns}
             rows={sales}
             getRowId={(s) => s.id}
             initialState={{
               columns: { columnVisibilityModel: { location: true, userName: false, createdDate: false, } },
             }}
           />
         )}</TabPanel>
       
       <TabPanel value={valueTab} index={1}> {transfers && (
          <DataGrid
            loading={tableLoading}
            autoHeight
            density="compact"
            slots={{ toolbar: GridToolbar }}
            columns={transferColumns}
            rows={transfers}
            getRowId={(t) => t.id}
            initialState={{
              columns: { columnVisibilityModel: { location: true, userName: false, createdDate: false, } },
            }}
          />
        )}</TabPanel>
       
        <TabPanel value={valueTab} index={2}>{purchases && (
          <DataGrid
            loading={tableLoading}
            autoHeight
            density="compact"
            slots={{ toolbar: GridToolbar }}
            columns={purchaseColumns}
            rows={purchases}
            getRowId={(tank) => tank.id}
            initialState={{
              columns: { columnVisibilityModel: { location: true, userName: false, createdDate: false, } },
            }}
          />
        )} </TabPanel>
      </Box>
    </Stack>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 1 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}
