import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useCollectionOnce } from "react-firebase-hooks/firestore";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  Timestamp,
  serverTimestamp,
  doc,
  setDoc
} from "firebase/firestore";
import { db } from "../../services/firebase";
import { UserAuth } from "../../services/AuthContext";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import BackspaceTwoToneIcon from "@mui/icons-material/BackspaceTwoTone";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";

import { EditToolbar } from "../../common/edittoolbar";

import { DataGrid, GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";
import moment from "moment";

export const EditTanks = () => {
  const [locationId, setLocation] = useState("");
  const [tanks, setTanks] = useState();
  const [tanksLoading, setTankLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(true);
  const [rowModesModel, setRowModesModel] = useState({});
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const navigate = useNavigate();
  const { user } = UserAuth();

  const [locations, loadingLocations, errorLocations] = useCollectionOnce(
    query(
      collection(db, "locations-p"),
      where("active", "==", true),
      orderBy("name")
    )
  );

  const selectLocation = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setLocation(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (locationId !== "") {
        const q = query(
          collection(db, "locations-p", locationId, "tanks"),
          where("active", "==", active),
          orderBy("tankNumber")
        );
        const qs = await getDocs(q);

        const docs = [];

        qs.forEach((doc) => {
          docs.push({
            ...doc.data(),
            id: doc.id,
          });
        });

        setTankLoading(true);
        setLoading(true);
        setTanks(docs);
        setLoading(false);
        setTankLoading(false);
      }
    };

    fetchData();
  }, [active, locationId]);

  useEffect(() => {
    const fetchData = async () => {
      const q = query(
        collection(db, "products-p"),
        where("active", "==", true),
        orderBy("name")
      );
      const qs = await getDocs(q);

      const docs = [];

      qs.forEach((doc) => {
        docs.push({
          ...doc.data(),
          id: doc.id,
        });
      });

      console.log(docs);
      setProducts(docs);
    };

    fetchData();
  }, []);

  const columns = [
    {
      field: "tankNumber",
      headerName: "Tank Number",
      flex: 0.5,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: true,
    },
    {
      field: "productId",
      headerName: "Product",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: products,
      getOptionValue: (value) => value.id,
      getOptionLabel: (value) => value.name,
    },
    { field: "name", headerName: "Tank Description", flex: 1, editable: true },
    {
      field: "capacity",
      headerName: "Capacity",
      flex: 0.5,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: true,
    },
    {
      field: "active",
      headerName: "Active",
      flex: 1,
      type: "boolean",
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        //console.log(rowModesModel);
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  // https://codesandbox.io/s/0o2gny?file=/demo.js:2661-3076
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => async () => {
    //setRows(rows.filter((row) => row.id !== id));
    setLoading(true);
    //const done = await new Promise((res) => setTimeout(res, 2500));
    const deleted = {
      active: false,
      lastUpdatedBy: user.uid,
      lastUpdated: serverTimestamp()
    }

    const docRef = doc(db, "locations-p", locationId, "tanks", id);
    await setDoc(docRef, deleted, {merge: true});
    setLoading(false);
    setTanks(tanks.map(t => {
      if (t.id === id){
        return {...t, active: false};
      }
      return t;
    }));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const handleNew = async () => {

    setLoading(true);
    //const done = await new Promise((res) => setTimeout(res, 2500));
    const newRef = doc(collection(db, "locations-p", locationId, "tanks"));
    console.log(newRef.id);
    const id = newRef.id;
    setLoading(false);

    setTanks((oldRows) => [
      ...oldRows,
      {
        id,
        tankNumber: 0,
        name: "",
        productId: "",
        locationId: locationId,
        capacity: 0,
        active: true,
        lastUpdated: new Timestamp(),
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "tankNumber" },
    }));
  };

  const processRowUpdate = async (newRow) => {

    const updatedRow = { ...newRow, isNew: false };
    //setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    setLoading(true);
    //const done = await new Promise((res) => setTimeout(res, 2500));

    newRow.lastUpdatedBy = user.uid;
    newRow.lastUpdated = serverTimestamp();

    const docRef = doc(db, "locations-p", locationId, "tanks", newRow.id);
    await setDoc(docRef, newRow, {merge: true});

    setLoading(false);

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      spacing={1}
    >
      <Box display="flex" justifyContent="space-between" p={2}>
        <Typography variant="h5">Manage Tanks for Location</Typography>

        <Box display="flex">
          <Button
            variant="text"
            onClick={() => navigate('/maintenance')}
            startIcon={<BackspaceTwoToneIcon />}
          >
            Back
          </Button>
        </Box>
      </Box>
      {errorLocations && (
        <strong>Error: {JSON.stringify(errorLocations)}</strong>
      )}
      {loadingLocations && <CircularProgress></CircularProgress>}
      {locations && (
        <FormControl fullWidth>
          <InputLabel id="location-select-label">Select Location</InputLabel>
          <Select
            labelId="location-select-label"
            id="location-select"
            label="Location"
            inputProps={{ MenuProps: { disableScrollLock: true } }}
            value={locationId}
            onChange={(event) => {
              selectLocation(event);
            }}
          >
            {locations.docs.map((doc) => (
              <MenuItem key={doc.id} value={doc.id} name={doc.data().name}>
                {doc.data().name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {tanksLoading && <Skeleton fullWidth height="220px"></Skeleton>}
      {tanks && (
        <DataGrid
          loading={loading}
          autoHeight
          density="compact"
          pageSizeOptions={[5, 10, 25]}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          columns={columns}
          rows={tanks}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: { setActive, handleNew, label: "Tank" },
          }}
        />
      )}
    </Stack>
  );
};
