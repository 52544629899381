import { useState, useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../services/firebase";
import { doc, onSnapshot } from "firebase/firestore";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { Unauthorized } from "../common/unauthorized";

export const Dashboard = () => {
  const [surveyType, setSurveyType] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const [role, setRole] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const pathnames = location.pathname.split('/').filter((x) => x);
    if (pathnames.length > 1) {
      setSurveyType(pathnames[1].toUpperCase());
    } else {
      setSurveyType("");
    }
  }, [location]);

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");

    const unsubscribe = onSnapshot(doc(db, "users", user?.uid), (doc) => {
      setRole(doc.data().role);
      //setRole("Admin");
    });

    return () => unsubscribe();
  }, [user, loading, navigate]);

  return (
    <div>
      {(!role || role !== "Admin") && <Unauthorized></Unauthorized>}
      {!surveyType && role === "Admin" && (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={1}
        >
          <Box display="flex" justifyContent="space-between" p={2}>
            <Typography variant="h5">Analytics</Typography>

            <Box display="flex"></Box>
          </Box>

          <Button
            variant="contained"
            size="large"
            onClick={() => navigate('/dashboard/shrinks')}
          >
            Gain/Shrink by Location
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={() => navigate('/dashboard/transfers')}
          >
            Transfer History by Location
          </Button>
          
          <Button
            variant="contained"
            size="large"
            onClick={() => navigate('/dashboard/activity')}
          >
            Activity by Location
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={() => navigate('/dashboard/capacities')}
          >
            Capacity by Product/Macro
          </Button>
        </Stack>
      )}
      <Outlet />
    </div>
  );
};
