import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useCollectionOnce } from "react-firebase-hooks/firestore";
import {
  collection,
  query,
  where,
  orderBy,
  Timestamp,
} from "firebase/firestore";
import { db, fetchDocDictionary } from "../../services/firebase";
import TransactionDataService from "../../services/transactions.service";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import BackspaceTwoToneIcon from "@mui/icons-material/BackspaceTwoTone";
import RefreshIcon from "@mui/icons-material/Refresh";
import Typography from "@mui/material/Typography";

import { Formik, Form, Field } from "formik";
import { CustomizedSelect } from "../../common/select";

import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";

export const ReportTransfers = () => {
  const [dateBegin, setDateBegin] = useState(dayjs());
  const [dateEnd, setDateEnd] = useState(dayjs());
  const formikRef = useRef();
  const navigate = useNavigate();

  const [tableLoading, setTableLoading] = useState(false);
  const [transfers, setTransfers] = useState([]);

  const [products, setProducts] = useState();

  const [locations, locationsLoading, locationsError] = useCollectionOnce(
    query(
      collection(db, "locations-p"),
      where("active", "==", true),
      orderBy("name")
    )
  );

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("dateBegin", dateBegin);
    }
  }, [dateBegin]);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("dateEnd", dateEnd);
    }
  }, [dateEnd]);

  const transferColumns = [
    {
      field: "locationOut",
      headerName: "Location Out",
      flex: 1,
      headerAlign: "left",
      editable: false,
    },
    {
      field: "locationIn",
      headerName: "Location In",
      flex: 1,
      headerAlign: "left",
      editable: false,
    },
    { field: "productName", headerName: "Product", flex: 1, editable: false },
    { field: "productMacro", headerName: "Macro", flex: 1, editable: false },
    {
      field: "gallons",
      headerName: "Gallons",
      flex: 1,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    {
      field: "reportingDate",
      headerName: "Date",
      flex: 1,
      type: "dateTime",
      valueGetter: ({ value }) => value && value.toDate(),
      renderCell: (params) =>
        moment(params.row.reportingDate.toDate()).format("M/D/YY"),
    },
  ];

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      spacing={1}
    >
      <Box display="flex" justifyContent="space-between" p={2}>
        <Typography variant="h5">Transfers</Typography>

        <Box display="flex">
          <Button
            variant="text"
            onClick={() => navigate("/dashboard")}
            startIcon={<BackspaceTwoToneIcon />}
          >
            Back
          </Button>
        </Box>
      </Box>
      <Formik
        innerRef={formikRef}
        initialValues={{ locationId: "", dateBegin: dayjs(), dateEnd: dayjs() }}
        onSubmit={async (values, helpers) => {
          //console.log("sumbitted", values);

          setTableLoading(true);

          let productsDictionary = products;

          if (!productsDictionary) {
            const dict = await fetchDocDictionary(
              query(collection(db, "products-p"), where("active", "==", true))
            );
            setProducts(dict);
            productsDictionary = dict;
            //console.log("products-p", productsDictionary);
          }

          const response = await TransactionDataService.getTransfers(
            values.locationId,
            dateBegin.startOf("day").toDate(),
            dateEnd.startOf("day").toDate()
          );

          console.log("transfers", response.data);

          const tr = [];

          response.data.forEach((t) => {
            const locationOut = locations.docs.find(
              (l) => l.id === t.locationidout
            );
            const locationIn = locations.docs.find(
              (l) => l.id === t.locationidin
            );

            tr.push({
              id: t.transfer,
              locationOut: locationOut?.data()?.name,
              locationIn: locationIn?.data()?.name,
              productName: productsDictionary[t.productId].name,
              productMacro: productsDictionary[t.productId].macro,
              gallons: t.gallons,
              reportingDate: dayjs(t.reportingDate),
            });
          });

          setTransfers(
            tr.sort((t1, t2) =>
              t1.reportingDate > t2.reportingDate
                ? 1
                : t1.reportingDate < t2.reportingDate
                ? -1
                : 0
            )
          );

          //const done = await new Promise((res) => setTimeout(res, 2500));
          setTableLoading(false);
        }}
      >
        {({ values, errors, isSubmitting }) => (
          <Form>
            {locationsError && (
              <Grid item xs={12}>
                <strong>Error: {JSON.stringify(locationsError)}</strong>
              </Grid>
            )}
            {locationsLoading && (
              <Grid item xs={12}>
                <Skeleton />
              </Grid>
            )}
            {locations && (
              <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                  <Field
                    fullWidth
                    component={CustomizedSelect}
                    label="Select Location"
                    name={`locationId`}
                  >
                    <MenuItem value="" name="Location Not Selected" selected>
                      Location Not Selected
                    </MenuItem>
                    {locations.docs.map((doc) => (
                      <MenuItem
                        key={doc.id}
                        value={doc.id}
                        name={doc.data().name}
                      >
                        {doc.data().name}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>

                <Grid item xs={6} md={3}>
                  <DatePicker
                    fullWidth
                    name="dateBegin"
                    label="Begin"
                    value={dateBegin}
                    onChange={(newValue) => setDateBegin(newValue)}
                  ></DatePicker>
                </Grid>
                <Grid item xs={6} md={3}>
                  <DatePicker
                    fullWidth
                    name="dateEnd"
                    label="End"
                    value={dateEnd}
                    onChange={(newValue) => setDateEnd(newValue)}
                  ></DatePicker>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                    startIcon={
                      isSubmitting ? (
                        <CircularProgress size="0.9rem" />
                      ) : (
                        <RefreshIcon />
                      )
                    }
                  >
                    {isSubmitting ? "Refreshing" : "Refresh"}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Form>
        )}
      </Formik>
      <Box sx={{ width: "100%" }}>
        <DataGrid
          loading={tableLoading}
          autoHeight
          density="compact"
          slots={{ toolbar: GridToolbar }}
          columns={transferColumns}
          rows={transfers}
          getRowId={(p) => p.id}
          initialState={{
            columns: { columnVisibilityModel: { location: false } },
          }}
        />
      </Box>
    </Stack>
  );
};
