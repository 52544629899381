import http from "./http-common";

class InventoryDataService {
  getAll() {
    return http.get("/inventories-p");
  }

  getForLocation(locationId, begin, end) {
    return http.get(`/inventories-p?begin=${begin}&end=${end}&locationId=${locationId}`);
  }

  getForDates(begin, end) {
    return http.get(`/inventories-p?begin=${begin}&end=${end}`);
  }

  getShrinkForLocation(locationId, begin, end) {
    return http.get(`/inventories-p/shrink?begin=${begin}&end=${end}&locationId=${locationId}`);
  }

  getShrinkForDates(begin, end) {
    return http.get(`/inventories-p/shrink?begin=${begin}&end=${end}`);
  }

  getActivityForLocation(locationId, begin, end) {
    return http.get(`/inventories-p/activity?begin=${begin}&end=${end}&locationId=${locationId}`);
  }

  getActivityForDates(locationId, begin, end) {
    return http.get(`/inventories-p/activity?begin=${begin}&end=${end}`);
  }

  get(id) {
    return http.get(`/inventories-p/${id}`);
  }

  create(data) {
    return http.post("/inventories-p", data);
  }

  delete(id) {
    return http.delete(`/inventories-p/${id}`);
  }
}

export default new InventoryDataService();
