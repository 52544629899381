import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useCollectionOnce } from "react-firebase-hooks/firestore";
import {
  collection,
  query,
  where,
  orderBy,
  Timestamp,
} from "firebase/firestore";
import { db, fetchDocDictionary } from "../../services/firebase";
import InventoryDataService from "../../services/inventories.service";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import BackspaceTwoToneIcon from "@mui/icons-material/BackspaceTwoTone";
import RefreshIcon from "@mui/icons-material/Refresh";
import Typography from "@mui/material/Typography";

import { Formik, Form, Field } from "formik";
import { CustomizedSelect } from "../../common/select";

import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";

export const ReportCapacities = () => {
  const [dateBegin, setDateBegin] = useState(dayjs());
  const [dateEnd, setDateEnd] = useState(dayjs());
  const formikRef = useRef();
  const navigate = useNavigate();

  const [tableLoading, setTableLoading] = useState(false);
  const [productCapacities, setProductCapacities] = useState([]);
  const [macroCapacities, setMacroCapacities] = useState([]);

  const [products, setProducts] = useState();

  const [valueTab, setValueTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const [locations, locationsLoading, locationsError] = useCollectionOnce(
    query(
      collection(db, "locations-p"),
      where("active", "==", true),
      orderBy("name")
    )
  );

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("dateBegin", dateBegin);
    }
  }, [dateBegin]);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("dateEnd", dateEnd);
    }
  }, [dateEnd]);

  const productColumns = [
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      headerAlign: "left",
      editable: false,
    },
    { field: "productName", headerName: "Product", flex: 1, editable: false },
    { field: "productMacro", headerName: "Macro", flex: 1, editable: false },
    {
      field: "gallons",
      headerName: "Gallons",
      flex: 1,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    {
      field: "percentOfCapacity",
      headerName: "%",
      flex: 0.5,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }

        const valueFormatted = Math.round(
          Number(params.value * 100)
        ).toLocaleString();
        return `${valueFormatted} %`;
      },
    },
    {
      field: "reportingDate",
      headerName: "Date",
      flex: 1,
      type: "dateTime",
      valueGetter: ({ value }) => value && value.toDate(),
      renderCell: (params) =>
        moment(params.row.reportingDate.toDate()).format("M/D/YY"),
    },
  ];

  const macroColumns = [
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      headerAlign: "left",
      editable: false,
    },
    { field: "productMacro", headerName: "Macro", flex: 1, editable: false },
    {
      field: "gallons",
      headerName: "Gallons",
      flex: 1,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    {
      field: "percentOfCapacity",
      headerName: "%",
      flex: 0.5,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }

        const valueFormatted = Math.round(
          Number(params.value * 100)
        ).toLocaleString();
        return `${valueFormatted} %`;
      },
    },
    {
      field: "reportingDate",
      headerName: "Date",
      flex: 1,
      type: "dateTime",
      valueGetter: ({ value }) => value && value.toDate(),
      renderCell: (params) =>
        moment(params.row.reportingDate.toDate()).format("M/D/YY"),
    },
  ];

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      spacing={1}
    >
      <Box display="flex" justifyContent="space-between" p={2}>
        <Typography variant="h5">Capacity</Typography>

        <Box display="flex">
          <Button
            variant="text"
            onClick={() => navigate("/dashboard")}
            startIcon={<BackspaceTwoToneIcon />}
          >
            Back
          </Button>
        </Box>
      </Box>
      <Formik
        innerRef={formikRef}
        initialValues={{ locationId: "", dateBegin: dayjs(), dateEnd: dayjs() }}
        onSubmit={async (values, helpers) => {
          //console.log("sumbitted", values);

          setTableLoading(true);

          let productsDictionary = products;

          if (!productsDictionary) {
            const dict = await fetchDocDictionary(
              query(collection(db, "products-p"), where("active", "==", true))
            );
            setProducts(dict);
            productsDictionary = dict;
            //console.log("products-p", productsDictionary);
          }

          const inventories = [];

          if (values.locationId) {
            const response = await InventoryDataService.getForLocation(
              values.locationId,
              dateBegin.startOf("day").toDate(),
              dateEnd.startOf("day").toDate()
            );

            inventories.push(...response.data);
          } else {
            const response = await InventoryDataService.getForDates(
              dateBegin.startOf("day").toDate(),
              dateEnd.startOf("day").toDate()
            );

            inventories.push(...response.data);
          }

          const p = [];
          const m = [];

          inventories.forEach((i) => {
            if (i.tankId) {
              const location = locations.docs.find(
                (l) => l.id === i.locationId
              );

              let dateKey = dayjs(i.reportingDate).format("YYYYMMDD");
              let key = `${i.locationId}-${i.productId}-${dateKey}`;

              p[key] = {
                id: key,
                location: location?.data()?.name,
                productName: productsDictionary[i.productId].name,
                productMacro: productsDictionary[i.productId].macro,
                capacity: parseFloat(i.capacity) + (p[key]?.capacity ?? 0),
                gallons: parseFloat(i.gallons) + (p[key]?.gallons ?? 0),
                reportingDate: dayjs(i.reportingDate),
              };

              key = `${i.locationId}-${
                productsDictionary[i.productId].macro
              }-${dateKey}`;
              m[key] = {
                id: key,
                location: location?.data()?.name,
                productMacro: productsDictionary[i.productId].macro,
                capacity: parseFloat(i.capacity) + (m[key]?.capacity ?? 0),
                gallons: parseFloat(i.gallons) + (m[key]?.gallons ?? 0),
                reportingDate: dayjs(i.reportingDate),
              };
            }
          });

          setProductCapacities(
            Object.values(p)
              .map((element) => ({
                ...element,
                percentOfCapacity: element.gallons / element.capacity,
              }))
              .sort((c1, c2) =>
                c1.reportingDate > c2.reportingDate
                  ? -1
                  : c1.reportingDate < c2.reportingDate
                  ? 1
                  : c1.productName > c2.productName
                  ? 1
                  : c1.productName < c2.productName
                  ? -1
                  : 0
              )
          );
          setMacroCapacities(
            Object.values(m)
              .map((element) => ({
                ...element,
                percentOfCapacity: element.gallons / element.capacity,
              }))
              .sort((c1, c2) =>
                c1.reportingDate > c2.reportingDate
                  ? -1
                  : c1.reportingDate < c2.reportingDate
                  ? 1
                  : c1.productMacro > c2.productMacro
                  ? 1
                  : c1.productMacro < c2.productMacro
                  ? -1
                  : 0
              )
          );

          //const done = await new Promise((res) => setTimeout(res, 2500));
          setTableLoading(false);
        }}
      >
        {({ values, errors, isSubmitting }) => (
          <Form>
            {locationsError && (
              <Grid item xs={12}>
                <strong>Error: {JSON.stringify(locationsError)}</strong>
              </Grid>
            )}
            {locationsLoading && (
              <Grid item xs={12}>
                <Skeleton />
              </Grid>
            )}
            {locations && (
              <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                  <Field
                    fullWidth
                    component={CustomizedSelect}
                    label="Select Location"
                    name={`locationId`}
                  >
                    <MenuItem value="" name="Location Not Selected" selected>
                      Location Not Selected
                    </MenuItem>
                    {locations.docs.map((doc) => (
                      <MenuItem
                        key={doc.id}
                        value={doc.id}
                        name={doc.data().name}
                      >
                        {doc.data().name}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>

                <Grid item xs={6} md={3}>
                  <DatePicker
                    fullWidth
                    name="dateBegin"
                    label="Begin"
                    value={dateBegin}
                    onChange={(newValue) => setDateBegin(newValue)}
                  ></DatePicker>
                </Grid>
                <Grid item xs={6} md={3}>
                  <DatePicker
                    fullWidth
                    name="dateEnd"
                    label="End"
                    value={dateEnd}
                    onChange={(newValue) => setDateEnd(newValue)}
                  ></DatePicker>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                    startIcon={
                      isSubmitting ? (
                        <CircularProgress size="0.9rem" />
                      ) : (
                        <RefreshIcon />
                      )
                    }
                  >
                    {isSubmitting ? "Refreshing" : "Refresh"}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Form>
        )}
      </Formik>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={valueTab}
            onChange={handleChangeTab}
            aria-label="tabs"
            centered
            variant="fullWidth"
          >
            <Tab label="Products" {...a11yProps(0)} />
          </Tabs>
        </Box>
        <TabPanel value={valueTab} index={0}>
          {productCapacities && (
            <DataGrid
              loading={tableLoading}
              autoHeight
              density="compact"
              slots={{ toolbar: GridToolbar }}
              columns={productColumns}
              rows={productCapacities}
              getRowId={(p) => p.id}
              initialState={{
                columns: { columnVisibilityModel: { location: false } },
              }}
            />
          )}
        </TabPanel>
        <TabPanel value={valueTab} index={1}>
          {macroCapacities && (
            <DataGrid
              loading={tableLoading}
              autoHeight
              density="compact"
              slots={{ toolbar: GridToolbar }}
              columns={macroColumns}
              rows={macroCapacities}
              getRowId={(truck) => truck.id}
              initialState={{
                columns: { columnVisibilityModel: { location: false } },
              }}
            />
          )}
        </TabPanel>
      </Box>
    </Stack>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 1 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}
