import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useCollectionOnce } from "react-firebase-hooks/firestore";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { db, auth } from "../../services/firebase";
import TransactionDataService from "../../services/transactions.service";

import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { array, number, object, string } from "yup";
import { TextField } from "formik-mui";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import BackspaceTwoToneIcon from "@mui/icons-material/BackspaceTwoTone";
import SaveIcon from "@mui/icons-material/Save";
import Typography from "@mui/material/Typography";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";

import { enqueueSnackbar } from "notistack";

import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";

export const SurveyPurchase = () => {
  const [locationId, setLocation] = useState("");
  const [tanks, setTanks] = useState();
  const [tanksLoading, setTankLoading] = useState(false);
  const navigate = useNavigate();

  const [locations, loading, error] = useCollectionOnce(
    query(
      collection(db, "locations-p"),
      where("active", "==", true),
      orderBy("name")
    )
  );

  useEffect(() => {
    const fetchData = async () => {
      if (locationId !== "") {
        const q = query(
          collection(db, "locations-p", locationId, "tanks"),
          where("active", "==", true),
          orderBy("tankNumber")
        );
        const qs = await getDocs(q);

        const t = [];
        qs.forEach((doc) => {
          t.push({
            ...doc.data(),
            id: doc.id,
            gallons: 0,
            manifest: "",
          });
        });

        setTankLoading(true);
        setTanks(t);
        setTankLoading(false);
      }
    };

    fetchData();
  }, [locationId]);

  const selectLocation = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setLocation(event.target.value);
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      spacing={1}
    >
      <Box display="flex" justifyContent="space-between" p={2}>
        <Typography variant="h5">Purchase (BOL) Reporting</Typography>

        <Box display="flex">
          <Button
            variant="text"
            onClick={() => navigate("/survey")}
            startIcon={<BackspaceTwoToneIcon />}
          >
            Back
          </Button>
        </Box>
      </Box>
      {error && <strong>Error: {JSON.stringify(error)}</strong>}
      {loading && <CircularProgress></CircularProgress>}
      {locations && (
        <FormControl fullWidth>
          <InputLabel
            id="location-select-label"
            style={{ backgroundColor: "white" }}
          >
            Select Location
          </InputLabel>
          <Select
            labelId="location-select-label"
            id="location-select"
            label="Location"
            value={locationId}
            onChange={(event) => {
              selectLocation(event);
            }}
          >
            {locations.docs.map((doc) => (
              <MenuItem key={doc.id} value={doc.id} name={doc.data().name}>
                {doc.data().name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {tanksLoading && <Skeleton fullWidth height="220px"></Skeleton>}
      {tanks && <SurveyPurchaseForm locationId={locationId} tanks={tanks} />}
    </Stack>
  );
};

const SurveyPurchaseForm = (props) => {
  const [reportingDate, setReportingDate] = useState(dayjs());
  const formikRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("tanks", props.tanks);
    }
  }, [props.tanks]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{ tanks: props.tanks }}
      validationSchema={object({
        tanks: array(
          object({
            manifest: string(),
            gallons: number().integer("Please enter gallons in whole numbers"),
          })
        )
          .test({
            name: "gallons-for-manifest",
            message: "You must have greater than zero gallons for a manifest #",
            test: (val) =>
              val.every(({ manifest, gallons }, index) => {
                if (manifest !== undefined && gallons <= 0) {
                  return false;
                }
                return true;
              }),
          })
          .test({
            name: "manifest-for-gallons",
            message: "You must have a manifest # when entering gallons",
            test: (val) =>
              val.every(({ manifest, gallons }, index) => {
                if (manifest === undefined && gallons > 0) {
                  return false;
                }

                return true;
              }),
          }),
      })}
      onSubmit={async (values) => {
        const entryDate = reportingDate.startOf("day").toDate();

        for (const tank of values.tanks) {
          if (tank.gallons > 0 && tank.manifest !== undefined) {
            const data = {
              reportingDate: entryDate,
              type: "purchase",
              locationId: tank.locationId,
              tankId: tank.id,
              tankNumber: tank.tankNumber,
              productId: tank.productId,
              gallons: tank.gallons,
              manifest: tank.manifest,
              lastUpdatedBy: auth.currentUser.uid,
            };

            await TransactionDataService.create(data);
          }
        }

        //return new Promise((res) => setTimeout(res, 2500));
        enqueueSnackbar("Purchase Transactions Recorded");
        navigate("/survey");
      }}
    >
      {({ values, errors, isSubmitting }) => (
        <Form>
          <Stack spacing={0.5}>
            <Typography variant="h5" textAlign={"center"}>
              Enter Purchase (BOL) Information
            </Typography>
            <FieldArray name="tanks">
              {() => (
                <>
                  {values.tanks.map((tank, index) => (
                    <Grid container key={index} spacing={1}>
                      <Grid
                        item
                        xs={12}
                        sm
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography variant="h6">{tank.name}</Typography>
                      </Grid>
                      <Grid item xs={6} sm>
                        <Field
                          fullWidth
                          name={`tanks[${index}].gallons`}
                          component={TextField}
                          label="Gallons"
                          type="number"
                          variant="outlined"
                        ></Field>
                      </Grid>
                      <Grid item xs={6} sm>
                        <Field
                          fullWidth
                          name={`tanks[${index}].manifest`}
                          component={TextField}
                          label="Manifest #"
                          variant="outlined"
                        ></Field>
                      </Grid>
                    </Grid>
                  ))}
                </>
              )}
            </FieldArray>
            <Box display="flex" justifyContent="right">
              <ErrorMessage name="tanks" component="div" className="cehm-error"></ErrorMessage>
            </Box>
            <Box display="flex" justifyContent="right" p={2}>
              <Box display="flex" pr={2}>
                <DatePicker
                  name="reportingDate"
                  label="Reporting Date"
                  value={reportingDate}
                  onChange={(newValue) => setReportingDate(newValue)}
                ></DatePicker>
              </Box>
              <Box display="flex">
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                  startIcon={
                    isSubmitting ? (
                      <CircularProgress size="0.9rem" />
                    ) : (
                      <SaveIcon />
                    )
                  }
                >
                  {isSubmitting ? "Submitting" : "Submit"}
                </Button>
              </Box>
            </Box>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
